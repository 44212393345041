import { useCallback, useEffect, useRef, useState } from 'react';
import { shuffle, slice, take, uniqueId } from 'lodash';

import { Button } from '@/components/button/button';
import { Card } from '@/components/card/card';
import { english, hiragana, katakana } from '@/config';
import { Info } from '@/components/info/info';
import { recordResponse } from '@/utilities/api';
import { Results } from '@/components/results/results';

import { lookup } from '../lookup';
import style from './kana-quiz.module.scss';
import { Stack } from '@/components/stack/stack';

type Answer = {
  id: string;
  question: number;
  answer: number;
  correct: boolean;
  hiraganaMode: boolean;
  japaneseMode: boolean;
};

type Props = {
  japaneseMode: boolean;
  hiraganaMode: boolean;
  answerCount: number;
  config: {
    type: string;
    sysId: string;
  };
};

// const stagger: gsap.StaggerVars = {
//   grid: 'auto',
//   from: 'center',
//   amount: 0.1,
// };

// const ease = 'power2.out';

// const slideOut = async () =>
//   gsap.to(`.${style.answer}`, { delay: 1, duration: 0.2, y: 10, opacity: 0, scale: 0.4, ease, stagger }).then();
// const slideIn = async () =>
//   gsap.to(`.${style.answer}`, { duration: 0.2, y: 0, opacity: 1, scale: 1, ease, stagger }).then();

export function KanaQuiz(props: Props) {
  const { japaneseMode, hiraganaMode, answerCount, config } = props;

  const allKeys = useRef<Array<number>>(english.map((n, i) => i));
  const questionElement = useRef<HTMLDivElement>(null);
  const [itemKeys, setItemKeys] = useState<Array<number>>([]);
  const [questionIndex, setQuestionIndex] = useState<number | null>(null);
  const [submittedAnswers, setSubmittedAnswers] = useState<Array<Answer>>([]);
  const [lastClick, setlastClick] = useState(Date.now());
  const [locked, setLocked] = useState(false);

  // Grab the first N randomly shuffled items. The first one representing the testable item
  const updateQuestionsAndAnswers = useCallback(() => {
    const itemKeys = take(shuffle(allKeys.current), answerCount);
    setQuestionIndex(slice(itemKeys, 0, 1)[0]);
    setItemKeys(shuffle(itemKeys));
  }, [answerCount]);

  const onAnswer = async (answerIndex: number) => {
    if (locked) {
      return;
    }

    setLocked(true);
    const newLastClick = Date.now();
    const correct = questionIndex === answerIndex;
    const duration = newLastClick - lastClick;

    // japanese - true = japanese to english, false = english to japanese
    recordResponse(config.type, config.sysId, correct ? 5 : 1, {
      correct,
      duration,
      hiragana: hiraganaMode,
      japanese: japaneseMode,
      // @ts-ignore
      question: hiragana[questionIndex],
      answer: hiragana[answerIndex],
    });

    // fetch('https://api.usescout.io/api/events', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     source_id: 'wl',
    //     type: 'kana-quiz-answer',
    //     payload: {
    //       correct,
    //       duration,
    //       hiragana: hiraganaMode,
    //       japanese: japaneseMode,
    //       // @ts-ignore
    //       question: hiragana[questionIndex],
    //       answer: hiragana[answerIndex],
    //     },
    //   }),
    // });

    setSubmittedAnswers([
      {
        // @ts-ignore
        question: questionIndex,
        answer: answerIndex,
        hiraganaMode,
        japaneseMode,
        correct: correct,
        id: uniqueId(),
      },
      ...submittedAnswers,
    ]);
    setlastClick(newLastClick);

    questionElement.current?.classList.add(correct ? style.correct : style.incorrect);
    // await slideOut();
    questionElement.current?.classList.remove(style.correct, style.incorrect);
    updateQuestionsAndAnswers();
    // await slideIn();

    setLocked(false);
  };

  useEffect(() => {
    if (english.length !== hiragana.length || english.length !== katakana.length) {
      console.error('Count mismatch in KanaQuiz');
    }

    updateQuestionsAndAnswers();
  }, [updateQuestionsAndAnswers]);

  const resultValues = submittedAnswers.map((item) => {
    const { question, hiraganaMode, japaneseMode, answer, correct } = item;
    const questionLabel = lookup(question, hiraganaMode, japaneseMode);
    const answerLabel = lookup(answer, hiraganaMode, !japaneseMode);
    const correctAnswer = lookup(question, hiraganaMode, !japaneseMode);

    return {
      key: item.id,
      isCorrect: correct,
      text: correct
        ? `${questionLabel} - ${answerLabel}`
        : `${questionLabel} - You selected "${answerLabel}". The correct answer is "${correctAnswer}"`,
    };
  });

  if (!questionIndex) {
    // drop first render
    return null;
  }

  return (
    <Card>
      <Stack gap="space-3">
        <Info>Select the matching character. No time limits. Drill until it's second nature</Info>
        <div className={style.questionWrap}>
          <div className={style.question} ref={questionElement}>
            <span>{lookup(questionIndex, hiraganaMode, japaneseMode)}</span>
          </div>
        </div>
        <div className={style.answers}>
          {itemKeys.map((answerIndex, i) => (
            <div key={i} className={style.answerSpot}>
              <Button
                category="secondary"
                className={style.answer}
                onClick={() => onAnswer(answerIndex)}
                testId={`kana-answer-${i}`}
              >
                <span className={style.label}>{lookup(answerIndex, hiraganaMode, !japaneseMode)}</span>
              </Button>
            </div>
          ))}
        </div>
        <Results values={resultValues} />
      </Stack>
    </Card>
  );
}
