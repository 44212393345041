import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Howl } from 'howler';
import classNames from 'classnames';

import Pause from '@/icons/fa/solid/pause';
import Play from '@/icons/fa/solid/play';

import style from './audio.module.scss';

type AudioProps = {
  url?: string;
  label?: string;
  disabled?: boolean;
  preload?: boolean;
  variant?: 'small' | 'regular';
};

export type AudioRef = { play: () => void };

export const Audio = forwardRef<AudioRef, AudioProps>(function Audio(props, ref) {
  const player = useRef<Howl>();
  const { disabled, label, url, variant = 'regular', preload = true } = props;
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    return () => {
      player.current?.stop();
    };
  }, []);

  useEffect(() => {
    if (!url || disabled) {
      return;
    }
    player.current = new Howl({
      src: [url],
      html5: true,
      preload,
      autoplay: false,
      onend: () => setIsPlaying(false),
    });
  }, [url, disabled, preload]);

  const play = () => {
    if (!player.current) {
      return;
    }

    if (isPlaying) {
      setIsPlaying(false);
      player.current.pause();
    } else {
      setIsPlaying(true);
      player.current.play();

      // fetch('https://api.eventscout.io/api/events', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     source_id: 'wl',
      //     type: 'audio-listen',
      //     payload: {
      //       url,
      //     },
      //   }),
      // });
    }
  };

  useImperativeHandle(ref, () => ({
    play,
  }));

  return (
    <button
      className={classNames(style.root, style[variant], {
        [style.disabled]: disabled,
        [style.playing]: isPlaying,
        [style.withLabel]: label,
      })}
      onClick={play}
      title={label ?? 'Click to play'}
    >
      <div className={style.iconWrapper}>
        {isPlaying && <span className={style.pulse} />}
        {isPlaying ? <Pause className={style.icon} /> : <Play className={style.icon} />}
      </div>
      {label && <span className={style.label}>{label}</span>}
    </button>
  );
});
